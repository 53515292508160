@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Black.woff2') format('woff2'),
        url('../fonts/Gilroy-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2'),
        url('../fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2'),
        url('../fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2'),
        url('../fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Thin.woff2') format('woff2'),
        url('../fonts/Gilroy-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-SemiBold.woff2') format('woff2'),
        url('../fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


// Refinery 55

@font-face {
    font-family: 'Refinery 55';
    src: url('../fonts/Refinery-55Black.woff2') format('woff2'),
        url('../fonts/Refinery-55Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

