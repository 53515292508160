header{
    // position: fixed;
    width: 100%;
    .wrapper-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
    
        .nav-menu{
            ul{
                display: flex;
                padding: 0 0;
                a{
                    padding: 8px 20px;
                    font-size: 16px;
                    font-weight: 600;
                }
                @include devices(lg) {
                    display: none;
                    // code set for tablet
                }
            }
            .hamburger-menu{
                @include devices(minlg) {
                    display: none
                }
            }
        }
    
        .right-connectBtn{
            .border-btn{
                @include border-button;
            }
            @include devices(lg) {
                display: none;
            }
        }
    }
    
}

