
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    font-family: 'Gilroy';
}

html{
    scroll-behavior: smooth;
}

body {
    background-image: radial-gradient(rgba(251, 255, 97, 0.05) 6%, transparent 2%);
    background-color: #fff;
    background-position: 0 0, 29px 36px;
    background-size: 100px 100px;
    color: $Black100;
    font-size: 16px;
}

ul{
    padding:0;
    margin:0;
}

li{
    padding:0;
    margin:0;
    list-style-type: none;
}

a{
    text-decoration: none;
    cursor: pointer;
    color:$Black100;
    &:hover{
        color: $Primary-color100;
    }
}

a:-webkit-any-link{
    text-decoration: none;
    &:hover{
        color: $Primary-color100;
    }
}

.color-wt{
    color: $White;
}



.align-center{
    align-items: center;
}

.section{
    padding: 90px 0 90px;
}