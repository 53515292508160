@mixin border-button{
    border:1px solid $Black100;
    border-radius: 8px;
    padding: 16px 30px;
    color: $White;
    background-color: $Dark;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    transition: 0.6s;
    &:hover{
        box-shadow: 4px 4px 20px 0px rgba(220, 64, 38, 0.25);
      
    }
}

@mixin large-fill-button{
    background-color: $Primary-color100;
    color: $Black100;
    padding: 16px;
    width: 100%;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 8px;
    transition: 0.6s;
    border: 0;
    &:hover{
        
        color: $White;
        transition: 0.6s;
    }

}

@mixin btn-sm{
    border:0;
    border-radius: 12px;
    padding: 12px 20px;
    background-color: $Black100;
    font-size: 16px;
    color: $White;
    font-weight: 600;

    &:hover{
        background-color: $Primary-color100;
        color: $White;
    }
}


