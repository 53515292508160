//========== Break Point=========//

// We define the mixins for two major breakpoints mobile and tablet
 
 // $breakpoint is the variable that can have several values
 @mixin devices ($breakpoint) { //the name of the mixin is devices
  
    // max width
    @if $breakpoint == xxl {    
        @media only screen and (max-width: 1400px) {
        @content;
        }
    }

    @if $breakpoint == xl {    
        @media only screen and (max-width:1200px) {
        @content;
        }
    }

    @if $breakpoint == lg {    
        @media only screen and (max-width: 992px) {
        @content;
        }
    }

    @if $breakpoint == md {    
        @media only screen and (max-width: 768px) {
        @content;
        }
    }
 
     @if $breakpoint == sm {
        @media only screen and (max-width: 576px) {
        @content;
        }
    }

    // ==========min width==============//

    @if $breakpoint == minxxl {    
        @media only screen and (min-width: 1399px) {
        @content;
        }
    }

    @if $breakpoint == mininxl {    
        @media only screen and (min-width:1199px) {
        @content;
        }
    }

    @if $breakpoint == minlg {    
        @media only screen and (min-width: 991px) {
        @content;
        }
    }

    @if $breakpoint == minmd {    
        @media only screen and (min-width: 767px) {
        @content;
        }
    }
 
     @if $breakpoint == minsm {
        @media only screen and (min-width: 575px) {
        @content;
        }
    }
}



// ===========End Break Point===========//



// ==========Border-Radius=============//


// ===========End Border-Radius=========//