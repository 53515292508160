// Primary Color
$Primary-color100:#F04C23;
$Primary-color80:#F26222;
$Primary-color60:#F47820;
$Primary-color40:#F7901E;
$Primary-color20:#FBA919;



// Black Color
$Black100:#272727;
$Black80:#343434;
$Black60:#717171;
$Black40:#CFCFCF;
$Black20:#E1E1E1;

// main color:
$White:#F4F4F4;
$BGcolor:#FFF6F4;
$Dark: #000000;



body{
    background-color: $BGcolor;
}

