.grandient-bg{
    position: relative;
    img{
        position: absolute;
        // top: 94px;
        z-index: -1;
        width: 100%;
    }
}

.hero-bannerContent{
    padding-top: 120px;
    .content-left{
        .banner-content{
            margin-top: 60px;
            h1{
                font-size: 48px;
                color: $Black100;
            }
            p{
                font-size: 28px;
                color: $Black60;
                font-weight: 600;
            }
        }
    }
}

.hero-elements{
    position: absolute;
    top: 130px;
    right: 400px;
    @include devices(xl) {
        right: 300px;
      
    }
    @include devices(lg) {
       display: none;
      
    }
  
    .elements1{
        position: absolute;
        top: 500px;
       
    }

    .elements2{
        position: absolute;
        top: 300px;
        left: 150px;
    }

    .elements3{
        position: absolute;
        top: 100px;
        right: 150px;
        @include devices(xl) {
            right: 0px;
          
        }
    }
}

.hero-bannerSection {
    .button{
        button{
            @include btn-sm;
        }
    }
}