.main-heading{
    h1{
        font-size: 48px;
        color: $Black100;
        font-weight: 700;
    }
}

.main-paragraph{
    p{
        font-size: 24px;
        font-weight: 600;
        color: $Black60;
    }
}

.portfolio-section{
    padding: 180px 0;
}

.container1::-webkit-scrollbar {
    height: 8px;
  }
  
  .container1::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
  }
  
  .container1::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }

  .items-projects{
    img{
        width: 100%;
        border-radius: 20px;
    }
  }

  .project-items{
    width: 100%;
    display: -webkit-box;      
    display: -moz-box;         
    display: -ms-flexbox;     
    display: -webkit-flex;     
    display: flex; 
    justify-content: left;
    align-items: center;   
    overflow-x: auto;
    flex-flow:row; 
    .card-prject{
        background-position: center !important;
        background-size: cover !important;
        border-radius: 20px;
        height: 400px;
        min-width: 700px;
      }
    
  }
  

  .project-items{
    display: flex;
    gap: 30px;
  }